<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1">
      <div class="conducted-box">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header">
        <div class="block-divider">
          Шапка документу
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true"></date_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"></string_input>
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="operation"
                :import-getter="{ name: 'bank_document_outcome_operation' }"
                :input-value-as-value="false"
                label="Операція: "
                :disabled="without_accounting"
                @selectChange="onOperationChange"
                clearable
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <number_input label="Сума" v-model="sum" v-decimal
                          :disabled="operation === 'enumeration_salary' || operation === 'enumeration_tax'"
                          @userChange="changeForTaxCalc"
            />
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 316px; margin-right: 16px">
            <select_input
                v-model="chart_of_account_id"
                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                :computed-getter="'get_chart_of_accounts_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Рахунок (311-314)"
                :show-select-on-focus="false"
                :action-filter="'@field=type@;@start-with=S@31@@@;@field=type@;@start-with=S@БНК'"
                :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@31',
                                show: true
                              }"
                :disabled="without_accounting"
                clearable
            />
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="bank_account_id"
                :computed-action="'SELECT_BANK_ACCOUNT_LIST_ELEMENTS'"
                :computed-getter="'get_bank_account_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Банківський рахунок: "
                :select-button-object="{
                                name: 'BANK_ACCOUNT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список банківських рахунків',
                                element_title: 'Банківський рахунок',
                                show: true
                              }"
                clearable
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <select_input
                v-model="currency_id"
                :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                :computed-getter="'get_currency_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Валюта: "
                :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                clearable
            />
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1"
             v-if="operation === 'pay_from_provider'
             || operation === 'return_pay_from_customer'
             || operation === 'other_pay_from_contractor'
             || operation === 'enumeration_tax'"
        >
          <div style="flex: 0 0 316px; margin-right: 16px">
            <select_input
                v-model="contractor_id"
                :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                :computed-getter="'get_contractor_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :action-props="{currency_id: currency_id, date: date}"
                :show-additional-field="true"
                :row_num="0"
                label="Одержувач"
                :show-select-on-focus="false"
                :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                action_props: {currency_id: currency_id, date: date},
                                showAdditionalField: true
                              }"
                clearable
            />
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="contract_id"
                :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                :computed-getter="'get_contractor_contract_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                :owner_id="contractor_id"
                label="Договір"
                :action-props="{owner_id: contractor_id}"
                :show-select-on-focus="false"
                :sync-action-props="true"
                :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: contractor_id},
                                sync_action_props: true
                              }"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px"
               v-if="operation === 'pay_from_provider'
               || operation === 'return_pay_from_customer'
               || operation === 'other_pay_from_contractor'"
          >
            <v-checkbox label="Облік ПДВ"
                        v-model="tax_enable"
                        hide-details
                        color="success"
                        class="mt-3 pt-0 document-check-box"
                        @change="changeForTaxCalcWithFill"
            />
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 632px;">
            <textarea_input v-model="comment" label="Коментар: " :required="false"></textarea_input>
          </div>
        </div>
        <div>
          <v-tabs class="document-small-tabs" color="success">
            <v-tab>
              Платіжне доручення
            </v-tab>
            <v-tab>
              Бух.облік
            </v-tab>
            <v-tab v-if="tax_enable">
              ПДВ
            </v-tab>


            <v-tab-item class="pt-2">
              <div class="document-header-row">
                <div style="display: flex; width: 100%;" class="px-3 pt-2">
                  <div style="flex: 0 0 160px; margin-right: 16px">
                    <date_input v-model="date_document" label="Дата доручення: " :required="false"></date_input>
                  </div>
                  <div style="flex: 0 0 140px; margin-right: 16px">
                    <string_input v-model="number_document" label="Номер доручення: " :required="false"></string_input>
                  </div>
                  <div style="flex: 0 0 300px; margin-right: 16px">
                    <select_input
                        v-model="cash_flow_item_id"
                        :computed-action="'SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS'"
                        :computed-getter="'get_cash_flow_item_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        label="Стаття руху грошових коштів: "
                        :select-button-object="{
                                name: 'CASH_FLOW_ITEM_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список статей руху грошових коштів',
                                element_title: 'Стаття руху грошових коштів',
                                show: true
                              }"
                    />
                  </div>
                </div>
                <div style="display: flex;">
                  <div style="flex: 0 0 648px;" class="pt-3 pb-2 pl-3">
                    <textarea_input v-model="pay_comment" label="Призначення платежу: "
                                    :required="false"></textarea_input>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item class="pt-2">
              <div class="document-body">
                <div class="document-body-row px-3 pt-2 pb-1 grey lighten-5" style="display: block">
                  <div class="d-flex">
                    <div style="width: 316px; margin-right: 16px" v-if="operation !== 'enumeration_salary'">
                      <select_input
                          v-model="pay_chart_of_account_id"
                          :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                          :computed-getter="'get_chart_of_accounts_list_select'"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :label="pay_chart_of_account_label"
                          :show-select-on-focus="false"
                          :action-filter="getActionFilterForChart('type')"
                          :action-props="{by_bank: true}"
                          @selectChange="afterPayChartAccountChange"
                          :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: getActionFilterForChart('code')
                              }"
                          :disabled="without_accounting"
                      />
                    </div>
                    <div style="flex: 0 0 300px; margin-right: 16px"
                         v-if="operation === 'pay_from_provider'
                         || operation === 'return_pay_from_customer'
                         || operation === 'other_pay_from_contractor'"
                    >
                      <select_input
                          v-model="advance_chart_of_account_id"
                          :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                          :computed-getter="'get_chart_of_accounts_list_select'"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :label="'Рахунок авансу'"
                          :show-select-on-focus="false"
                          :action-filter="'@field=type@;@start-with=S@63@@@;@field=type@;@start-with=S@371@@@;@field=type@;@start-with=S@68'"
                          :action-props="{by_bank: true}"
                          @selectChange="afterPayChartAccountChange"
                          :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true,
                                action_filter: '@field=code@;@start-with=S@63@@@;@field=code@;@start-with=S@371@@@;@field=code@;@start-with=S@68'
                              }"
                          :disabled="without_accounting"
                          :required="false"
                      />
                    </div>
                  </div>
                  <div class="py-3" style="width: 632px;" v-if="operation === 'enumeration_salary'
                                                      || operation === 'enumeration_tax'">
                    <select_input
                        v-model="document_salary_to_pay_id"
                        :computed-action="'SELECT_DOCUMENT_SALARY_TO_PAY_ELEMENTS'"
                        :computed-getter="'get_document_salary_to_pay_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :action-props="{by_bank: true, date_to: this.date}"
                        label="Відомість на виплату: "
                        :select-button-object="{
                                name: 'DOCUMENT_SALARY_TO_PAY',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Переілк відомістей на виплату заробіної плати',
                                element_title: 'Відомість на виплату заробітної плати',
                                show: true,
                                document: true,
                                full_screen: true,
                                action_props: {by_bank: true, date_to: this.date}
                              }"
                        :disabled="operation === 'enumeration_tax' && !pay_chart_of_account_id"
                        @selectChange="onSalaryDocumentChange"
                    />
                  </div>
                  <div v-if="pay_chart_of_account_id &&
                  (operation === 'other_pay_outcome' || operation === 'enumeration_to_account_person')">
                    <div v-if="account_detail_1_type" class="py-3" style="width: 316px;">
                      <select_input
                          v-model="account_detail_1_value"
                          :computed-action="subconto_type_select_setting[account_detail_1_type].computed_action"
                          :computed-getter="subconto_type_select_setting[account_detail_1_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :label="subconto_type_select_setting[account_detail_1_type].label"
                          :show-select-on-focus="false"
                          :select-button-object="{
                                name: subconto_type_select_setting[account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_1_type].select_button_object.element_title,
                                show: true
                              }"
                      />
                    </div>
                    <div v-if="account_detail_2_type" class="py-1" style="width: 316px;">
                      <select_input
                          v-model="account_detail_2_value"
                          :computed-action="subconto_type_select_setting[account_detail_2_type].computed_action"
                          :computed-getter="subconto_type_select_setting[account_detail_2_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :owner_id="account_detail_2_type === 'contract' ? account_detail_1_value : null"
                          :action-props="account_detail_2_type === 'contract' ? {owner_id: account_detail_1_value} : null"
                          :sync-action-props="account_detail_2_type === 'contract'"
                          :label="subconto_type_select_setting[account_detail_2_type].label"
                          :show-select-on-focus="false"
                          :select-button-object="{
                                name: subconto_type_select_setting[account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_2_type].select_button_object.element_title,
                                show: true,
                                action_props: account_detail_2_type === 'contract' ? {owner_id: account_detail_1_value} : null,
                                sync_action_props: account_detail_2_type === 'contract'
                              }"
                      />
                    </div>
                    <div v-if="account_detail_3_type" class="py-1" style="width: 316px;">
                      <select_input
                          v-model="account_detail_3_value"
                          :computed-action="subconto_type_select_setting[account_detail_3_type].computed_action"
                          :computed-getter="subconto_type_select_setting[account_detail_3_type].computed_getter"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          :label="subconto_type_select_setting[account_detail_3_type].label"
                          :show-select-on-focus="false"
                          :select-button-object="{
                                name: subconto_type_select_setting[account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_3_type].select_button_object.element_title,
                                show: true
                              }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item v-if="tax_enable">
              <div class="document-body">
                <div class="px-3 pt-2 pb-1 grey lighten-5">
                  <div class="d-flex flex-nowrap">
                    <div style="flex: 0 0 316px; margin-right: 16px">
                      <v-checkbox label="Облік ПДВ"
                                  v-model="tax_enable"
                                  hide-details
                                  color="success"
                                  class="mt-1 pt-0 document-check-box"
                                  @change="changeForTaxCalcWithFill"
                      />
                    </div>
                    <div style="flex: 0 0 280px">
                      <v-checkbox label="ПДВ в тому числі"
                                  v-model="tax_in_sum"
                                  hide-details
                                  color="success"
                                  :disabled="!tax_enable"
                                  class="mt-1 pt-0 document-check-box"
                                  @change="changeForTaxCalc"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-nowrap mt-4">
                    <div style="flex: 0 0 316px; margin-right: 16px">
                      <select_input
                          v-model="tax_chart_of_account_id"
                          :disabled="!tax_enable"
                          :required="tax_enable"
                          :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                          :computed-getter="'get_chart_of_accounts_list_select'"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          label="Рах. подат. кредиту"
                          :show-select-on-focus="false"
                          :action-filter="'@field=type@;@start-with=S@644'"
                          :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@644',
                              show: true
                            }"
                          @selectChange="changeForTaxCalc"
                      />
                    </div>
                    <div style="flex: 0 0 290px; margin-right: 16px">
                      <select_input
                          v-model="tax_chart_of_account_id_not_confirm"
                          :disabled="!tax_enable"
                          :required="tax_enable"
                          :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                          :computed-getter="'get_chart_of_accounts_list_select'"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :row_num="0"
                          label="Рах. подат. кредиту (непідтверджений)"
                          :show-select-on-focus="false"
                          :action-filter="'@field=type@;@start-with=S@644'"
                          :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@644',
                              show: true
                            }"
                          @selectChange="changeForTaxCalc"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-nowrap mt-4">
                    <div style="flex: 0 0 180px; margin-right: 16px">
                      <select_input
                          v-model="tax_id"
                          :disabled="!tax_enable"
                          :required="tax_enable"
                          label="Ставка ПДВ"
                          :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                          :computed-getter="'get_taxes_list_select'"
                          :input-value-as-value="false"
                          :item-search="'text'"
                          :show-on-focus="false"
                          :show-select-on-focus="false"
                          :action-filter="'@field=type@;@start-with=S@pdv'"
                          :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true,
                                action_filter: '@field=tax_group@;@start-with=S@pdv'
                              }"
                          @selectChange="changeForTaxCalc"
                      />
                    </div>
                    <div style="flex: 0 0 120px; margin-right: 16px">
                      <number_input label="Сума ПДВ"
                                    :disabled="!tax_enable"
                                    v-model="tax_sum"
                                    :required="tax_enable"
                                    v-decimal
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS,
  UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS,
  REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS,
  CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {subconto_type_select_setting} from '@/utils/accounting'
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import bankOutcomeAPI from "@/utils/axios/accounting/documents/bank_outcome"
import actWorkPerformedIncome from "@/utils/axios/accounting/documents/act_work_performed_income"
import invoiceWarehouseIncome from "@/utils/axios/accounting/documents/invoice_warehouse_income"
import {format_date_from_python, round_number} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component_wto";

const modalDeleteId = 'bank_outcome_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_BankOutcome",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    textarea_input: () => import("@/components/accounting/input/document_text_area_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
  },
  data() {
    return {
      subconto_type_select_setting,
      operation: this.item.operation || null,
      contractor_id: this.item.contractor_id || null,
      contract_id: this.item.contract_id || null,
      bank_account_id: this.item.bank_account_id,
      sum: this.item.sum || 0,
      currency_id: this.item.currency_id || null,
      without_accounting: this.item.without_accounting || false,
      comment: this.item.comment || null,
      pay_comment: this.item.pay_comment || null,
      date_document: this.item.date_document || null,
      number_document: this.item.number_document || null,
      cash_flow_item_id: this.item.cash_flow_item_id || null,
      pay_chart_of_account_id: this.item.pay_chart_of_account_id || null,
      chart_of_account_id: this.item.chart_of_account_id || null,
      account_detail_1_value: this.item.account_detail_1_value,
      account_detail_1_type: this.item.account_detail_1_type,
      account_detail_2_value: this.item.account_detail_2_value,
      account_detail_2_type: this.item.account_detail_2_type,
      account_detail_3_value: this.item.account_detail_3_value,
      account_detail_3_type: this.item.account_detail_3_type,
      document_salary_to_pay_id: this.item.document_salary_to_pay_id || null,
      tax_enable: this.item.tax_enable || false,
      tax_in_sum: this.item.tax_in_sum || false,
      advance_chart_of_account_id: this.item.advance_chart_of_account_id || null,
      tax_chart_of_account_id: this.item.tax_chart_of_account_id || null,
      tax_chart_of_account_id_not_confirm: this.item.tax_chart_of_account_id_not_confirm || null,
      tax_id: this.item.tax_id || null,
      tax_sum: this.item.tax_sum || 0,
    }
  },
  methods: {
    doConducted(payload) {
      if (this.isNew) return

      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      this.$store.dispatch(CONDUCTED_DOCUMENT_BANK_OUTCOME_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
        .then(data => {
          if (data) {
            const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
            const color = payload ? 'success' : 'error'
            this.conducted = payload
            this.$store.commit(ALERT_SHOW, { text, color })
          }

        })
    },
    onOperationChange(payload) {
      const value = (payload || {}).value || null

      if (value) {
        const chart_advance = (this.charts.find(item => item.type === '3711') || {})
        if (value === 'pay_from_provider') {
          const chart = (this.charts.find(item => item.type === '631') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
          if (chart_advance.value) {
            this.advance_chart_of_account_id = chart_advance.value
          } else {
            this.advance_chart_of_account_id = null
          }
        }
        if (value === 'return_pay_from_customer') {
          const chart = (this.charts.find(item => item.type === '361') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
          if (chart_advance.value) {
            this.advance_chart_of_account_id = chart_advance.value
          } else {
            this.advance_chart_of_account_id = null
          }
        }
        if (value === 'other_pay_from_contractor') {
          const chart = (this.charts.find(item => item.type === '6851') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
          if (chart_advance.value) {
            this.advance_chart_of_account_id = chart_advance.value
          } else {
            this.advance_chart_of_account_id = null
          }
        }
        if (value === 'enumeration_to_account_person') {
          const chart = (this.charts.find(item => item.type === '3721') || {})
          if (chart.value) {
            this.pay_chart_of_account_id = chart.value
            this.afterPayChartAccountChange(chart)
          } else {
            this.pay_chart_of_account_id = null
          }
        }
      }
    },
    onSalaryDocumentChange(payload, chart_id) {
      if (payload) {
        const pay_chart_of_account_id = chart_id || this.pay_chart_of_account_id
        const code_651 = (this.charts.find(item => item.type === '651') || {}).value
        const code_6411 = (this.charts.find(item => item.type === '6411') || {}).value
        const code_642 = (this.charts.find(item => item.type === '642') || {}).value

        const request_payload = {
          document_id: payload.id || payload.value
        }
        if (this.operation === 'enumeration_salary') {
          request_payload['table'] = 'charges'
        }
        if (this.operation === 'enumeration_tax') {
          if (pay_chart_of_account_id === code_651) {
            request_payload['table'] = 'contribution'
          }
          if (pay_chart_of_account_id === code_6411) {
            request_payload['table'] = 'tax_pdfo'
          }
          if (pay_chart_of_account_id === code_642) {
            request_payload['table'] = 'tax_military'
          }
        }
        bankOutcomeAPI.get_salary_sum_to_pay(request_payload)
            .then(response => response.data)
            .then(data => this.sum = data)
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      } else {
        this.sum = 0
      }
    },
    afterPayChartAccountChange(payload) {
      this.account_detail_1_value = null
      this.account_detail_1_type = null
      this.account_detail_2_value = null
      this.account_detail_2_type = null
      this.account_detail_3_value = null
      this.account_detail_3_type = null

      if (payload) {

        if (this.operation === 'enumeration_tax' && this.document_salary_to_pay_id) {
          this.onSalaryDocumentChange({id: this.document_salary_to_pay_id}, payload.value)
        }

        if ('text' in payload && 'value' in payload) {
          chartOfAccountAPI.get_by_id({item_id: payload.value})
              .then(response => response.data)
              .then(data => {
                const details = data.details || []
                const obj_1 = details.find(i => i.order === 1)
                const obj_2 = details.find(i => i.order === 2)
                const obj_3 = details.find(i => i.order === 3)

                if (obj_1) {
                  this.account_detail_1_type = obj_1.detail_type
                }
                if (obj_2) {
                  this.account_detail_2_type = obj_2.detail_type
                }
                if (obj_3) {
                  this.account_detail_3_type = obj_3.detail_type
                }
              })
        } else {
          const details = payload.details || []
          const obj_1 = details.find(i => i.order === 1)
          const obj_2 = details.find(i => i.order === 2)
          const obj_3 = details.find(i => i.order === 3)

          if (obj_1) {
            this.account_detail_1_type = obj_1.detail_type
          }
          if (obj_2) {
            this.account_detail_2_type = obj_2.detail_type
          }
          if (obj_3) {
            this.account_detail_3_type = obj_3.detail_type
          }
        }

      }
    },
    getActionFilterForChart(field) {
      if (!this.operation) return `@field=${field}@;@start-with=S@000`
      if (this.operation === 'return_pay_from_customer') {
        return `@field=${field}@;@start-with=S@36@@@;@field=${field}@;@start-with=S@37@@@;@field=${field}@;@start-with=S@РЗР`
      }
      if (this.operation === 'pay_from_provider') {
        return `@field=${field}@;@start-with=S@63@@@;@field=${field}@;@start-with=S@68@@@;@field=${field}@;@start-with=S@РЗР`
      }
      if (this.operation === 'other_pay_from_contractor') {
        return `@field=${field}@;@start-with=S@36@@@;@field=${field}@;@start-with=S@37@@@;@field=${field}@;@start-with=S@63@@@;@field=${field}@;@start-with=S@68@@@;@field=${field}@;@start-with=S@РЗР`
      }
      return ''
    },

    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'operation': {id: 'operation', text: 'Не заповнена операція'},
        'bank_account_id': {id: 'operation', text: 'Не заповнений банківський рахунок'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена валюта'},
        'sum': {id: 'sum', text: 'Не заповнена сума платіжного доручення'},
        'cash_flow_item_id': {id: 'currency_id', text: 'Не заповнена стаття руху грошових коштів'},
        'chart_of_account_id': {id: 'currency_id', text: 'Не заповнений рахунок бух. обліку (сторінка бух.облік)'}
      }

      if (this.operation === 'enumeration_salary') {
        header['document_salary_to_pay_id'] = {id: 'document_salary_to_pay_id', text: 'Не заповнена відомість на виплату заробітної плати'}
      }
      if (this.operation === 'enumeration_tax') {
        header['document_salary_to_pay_id'] = {id: 'document_salary_to_pay_id', text: 'Не заповнена відомість на виплату податків та внесків'}
        header['contractor_id'] = {id: 'contractor_id', text: 'Не заповнений платник'}
        header['contract_id'] = {id: 'contract_id', text: 'Не заповнений договір платника'}
      }
      if (this.operation !== 'enumeration_salary') {
        header['pay_chart_of_account_id'] =  {id: 'currency_id', text: this.pay_chart_of_account_label}
      }

      if (this.operation === 'pay_from_provider'
          || this.operation === 'return_pay_from_customer'
          || this.operation === 'other_pay_from_contractor') {
        header['contractor_id'] = {id: 'contractor_id', text: 'Не заповнений платник'}
        header['contract_id'] = {id: 'contract_id', text: 'Не заповнений договір платника'}
        if (this.tax_enable) {
          header['tax_chart_of_account_id'] = {id: 'tax_chart_of_account_id', text: 'Не заповнений рах. податкового кредиту'}
          header['tax_chart_of_account_id_not_confirm'] = {id: 'tax_chart_of_account_id_not_confirm', text: 'Не заповнений рах. податкового кредиту (не підтвердженне)'}
          header['tax_id'] = {id: 'tax_id', text: 'Не заповнена ставка ПДВ'}
          header['tax_sum'] = {id: 'tax_sum', text: 'Не заповнена сума ПДВ'}
        }
      }

      if (this.operation === 'other_pay_outcome' || this.operation === 'enumeration_to_account_person') {
        if (this.account_detail_1_type) {
          header['account_detail_1_value'] = {
            id: 'account_detail_1_value',
            text: `Не заповнено субконто №1: ${this.subconto_type_select_setting[this.account_detail_1_type].select_button_object.element_title}`
          }
        }

        if (this.account_detail_2_type) {
          header['account_detail_2_value'] = {
            id: 'account_detail_2_value',
            text: `Не заповнено субконто №2: ${this.subconto_type_select_setting[this.account_detail_2_type].select_button_object.element_title}`
          }
        }

        if (this.account_detail_3_type) {
          header['account_detail_3_value'] = {
            id: 'account_detail_3_value',
            text: `Не заповнено субконто №3: ${this.subconto_type_select_setting[this.account_detail_3_type].select_button_object.element_title}`
          }
        }
      }


      const document_table = {
        // 'tax_id': {id: 'tax_id', table: 'Таблиця', text: 'Не заповнена ставка ПДВ'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(document_table, 'Таблиця', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }
    },


    getTaxTariff(tax_id) {
      if (!tax_id) return null
      const tariffs = {}
      const pdv_without = this.settings?.pdv_without?.value || 0
      const pdv_0 = this.settings?.pdv_0?.value || 0
      const pdv_5 = this.settings?.pdv_5?.value || 0
      const pdv_7 = this.settings?.pdv_7?.value || 0
      const pdv_9 = this.settings?.pdv_9?.value || 0
      const pdv_20 = this.settings?.pdv_20?.value || 0

      tariffs[pdv_without] = null
      tariffs[pdv_0] = 0
      tariffs[pdv_5] = 5
      tariffs[pdv_7] = 7
      tariffs[pdv_9] = 9
      tariffs[pdv_20] = 20

      return tariffs[tax_id] || null
    },
    fillPdvCharts() {
      this.tax_chart_of_account_id = this.settings?.default_pdv_credit_chart?.value
      this.tax_chart_of_account_id_not_confirm = this.settings?.default_pdv_credit_not_confirmed_chart?.value
      this.tax_id = this.settings?.default_pdv_percent?.value
      this.tax_in_sum = true
    },
    changeForTaxCalcWithFill() {
      this.$nextTick(() => {
        this.fillPdvCharts()
        this.calcTaxSum()
      })
    },
    changeForTaxCalc() {
      this.$nextTick(() => {
        this.calcTaxSum()
      })
    },
    calcTaxSum() {
      if (!this.tax_id) {
        this.tax_sum = 0
        return
      }
      if (this.tax_enable) {
        const tax_tariff = this.getTaxTariff(this.tax_id)
        if (this.tax_in_sum) {
          this.tax_sum = round_number(this.sum / (100 / tax_tariff + 1), 2)
        } else {
          this.tax_sum = round_number(this.sum * tax_tariff / 100, 2)
        }
      } else {
        this.tax_sum = 0
      }
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.currency_id = local_data.currency_id || null
      this.operation = local_data.operation || null
      this.contractor_id = local_data.contractor_id || null
      this.contract_id = local_data.contract_id || null
      this.bank_account_id = local_data.bank_account_id || null
      this.sum = local_data.sum || 0
      this.comment = local_data.comment || ''
      this.pay_comment = local_data.pay_comment || ''
      this.date_document = local_data.date_document || null
      this.number_document = local_data.number_document || null
      this.cash_flow_item_id = local_data.cash_flow_item_id || null
      this.pay_chart_of_account_id = local_data.pay_chart_of_account_id || null
      this.document_salary_to_pay_id = local_data.document_salary_to_pay_id || null
      this.chart_of_account_id = local_data.chart_of_account_id || null
      this.account_detail_1_type = local_data.account_detail_1_type || null
      this.account_detail_2_type = local_data.account_detail_2_type || null
      this.account_detail_3_type = local_data.account_detail_3_type || null
      this.account_detail_1_value = local_data.account_detail_1_value || null
      this.account_detail_2_value = local_data.account_detail_2_value || null
      this.account_detail_3_value = local_data.account_detail_3_value || null
      this.advance_chart_of_account_id = local_data.advance_chart_of_account_id || null
      this.tax_chart_of_account_id = local_data.tax_chart_of_account_id || null
      this.tax_chart_of_account_id_not_confirm = local_data.tax_chart_of_account_id_not_confirm || null
      this.tax_enable = local_data.tax_enable || false
      this.tax_in_sum = local_data.tax_in_sum || false
      this.tax_sum = local_data.tax_sum || 0
      this.tax_id = local_data.tax_id
      this.without_accounting = local_data.without_accounting || false
    },
    fetch_data_by_id() {
      if (this.isNew) {
        if (this.item.basic_create_id) {
          if (this.item.basic_create_type === 'DOCUMENT_ACT_WORK_PERFORMED_INCOME') {
            actWorkPerformedIncome.get_document_by_id(this.item.basic_create_id)
                .then(response => response.data)
                .then(data => {
                  this.contractor_id = data.contractor_id
                  this.contract_id = data.contract_id
                  this.tax_enable = data.tax_enable
                  this.tax_in_sum = data.tax_in_sum
                  if (this.tax_enable) {
                    this.tax_id = data.tax_id
                  }
                  this.sum = data.total_result
                  this.tax_sum = data.total_tax
                  this.pay_chart_of_account_id = data.contractor_chart_of_account_id
                })
          }
          if (this.item.basic_create_type === 'DOCUMENT_INVOICE_WAREHOUSE_INCOME') {
            invoiceWarehouseIncome.get_document_by_id(this.item.basic_create_id)
                .then(response => response.data)
                .then(data => {
                  this.contractor_id = data.contractor_id
                  this.contract_id = data.contract_id
                  this.tax_enable = data.tax_enable
                  this.tax_in_sum = data.tax_in_sum
                  if (this.tax_enable) {
                    this.tax_id = data.tax_id
                  }
                  this.sum = data.total_result
                  this.tax_sum = data.total_tax
                  this.pay_chart_of_account_id = data.contractor_chart_of_account_id
                })
          }
        }
        return
      }

      bankOutcomeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.conducted = data.conducted || false
            this.date = data.date || null
            this.number = data.number || null
            this.currency_id = data.currency_id || null
            this.operation = data.operation || null
            this.contractor_id = data.contractor_id || null
            this.contract_id = data.contract_id || null
            this.bank_account_id = data.bank_account_id || null
            this.sum = data.sum || 0
            this.comment = data.comment || ''
            this.pay_comment = data.pay_comment || ''
            this.date_document = data.date_document || null
            this.number_document = data.number_document || null
            this.cash_flow_item_id = data.cash_flow_item_id || null
            this.pay_chart_of_account_id = data.pay_chart_of_account_id || null
            this.document_salary_to_pay_id = data.document_salary_to_pay_id || null
            this.chart_of_account_id = data.chart_of_account_id || null
            this.account_detail_1_type = data.account_detail_1_type || null
            this.account_detail_2_type = data.account_detail_2_type || null
            this.account_detail_3_type = data.account_detail_3_type || null
            this.account_detail_1_value = data.account_detail_1_value || null
            this.account_detail_2_value = data.account_detail_2_value || null
            this.account_detail_3_value = data.account_detail_3_value || null
            this.advance_chart_of_account_id = data.advance_chart_of_account_id || null
            this.tax_chart_of_account_id = data.tax_chart_of_account_id || null
            this.tax_chart_of_account_id_not_confirm = data.tax_chart_of_account_id_not_confirm || null
            this.tax_enable = data.tax_enable || false
            this.tax_in_sum = data.tax_in_sum || false
            this.tax_sum = data.tax_sum || 0
            this.tax_id = data.tax_id || null
            this.without_accounting = data.without_accounting || false
          })

    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення платіжного доручення (вихідне)  № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        operation: this.operation,
        contractor_id: this.contractor_id,
        contract_id: this.contract_id,
        bank_account_id: this.bank_account_id,
        sum: this.sum,
        currency_id: this.currency_id,
        comment: this.comment,
        pay_comment: this.pay_comment,
        date_document: this.date_document,
        number_document: this.number_document,
        cash_flow_item_id: this.cash_flow_item_id,
        pay_chart_of_account_id: this.pay_chart_of_account_id,
        chart_of_account_id: this.chart_of_account_id,
        account_detail_1_type: this.account_detail_1_type,
        account_detail_2_type: this.account_detail_2_type,
        account_detail_3_type: this.account_detail_3_type,
        account_detail_1_value: this.account_detail_1_value,
        account_detail_2_value: this.account_detail_2_value,
        account_detail_3_value: this.account_detail_3_value,
        without_accounting: this.without_accounting || false,
        document_salary_to_pay_id: this.document_salary_to_pay_id || null,
        advance_chart_of_account_id: this.advance_chart_of_account_id,
        tax_chart_of_account_id: this.tax_chart_of_account_id,
        tax_chart_of_account_id_not_confirm: this.tax_chart_of_account_id_not_confirm,
        tax_enable: this.tax_enable,
        tax_in_sum: this.tax_in_sum,
        tax_id: this.tax_id,
        tax_sum: this.tax_sum,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_BANK_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_BANK_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
              if (this.isNew) {
                this.onOperationChange({value: this.operation})
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_BANK_OUTCOME_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings'
    }),
    pay_chart_of_account_label() {
      switch (this.operation) {
        case 'pay_from_provider':
          return 'Рахунок розрахунків (63, 65 та ін.)'
        case 'return_pay_from_customer':
          return 'Рахунок розрахунків (36, 37 та ін.)'
        case 'other_pay_from_contractor':
          return 'Рахунок бух.обліку'
        case 'enumeration_to_account_person':
          return 'Рахунок бух.обліку (372)'
        case 'enumeration_salary':
          return 'Рахунок бух.обліку (661, 663)'
        case 'enumeration_tax':
          return 'Рахунок бух.обліку податків'
      }
      return ''
    },
  }
}
</script>
