import { mapGetters } from 'vuex'
import {formatDate} from "@/filters";
import {getSumColor, roundUp} from "@/utils/accounting";
import {round_number} from "@/utils/icons";

export default {
    props: ['title', 'item', 'dialog'],
    computed: {
        ...mapGetters({
            charts: 'get_chart_of_accounts_list_select',
            modalAnswer: 'question_answer',
            getModalLoading: 'get_modal_loading'
        }),
        isNew() {
            return this.item.id === undefined || this.item.id == null
        }
    },
    data() {
        return {
            show_print: false,
            formValid: false,
            watcher: null,
            watcher_item: null,
            delete_watcher: null,
            dialog_watcher: null,
            itemId: this.item.id,
            conducted: this.item.conducted || false,
            date: this.item.date || null,
            number: this.item.number || null,
            menu: false,
            document_state: {
                ready: false,
                header_ready: false,
                show: false,
                errors: []
            },
            start_info: {},
        }
    },
    methods: {
        formatDate,
        roundUp,
        round_number,
        getSumColor,
    },
    created() {
        this.watch_dialog()
    },
    beforeDestroy() {
        if (this.watcher) {
            this.watcher()
        }
        if (this.delete_watcher) {
            this.delete_watcher()
        }
        if (this.dialog_watcher) {
            this.dialog_watcher()
        }
        if (this.watcher_item) {
            this.watcher_item()
        }
    }
}